"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDefaultTheme = void 0;

var _default = _interopRequireDefault(require("./default"));

var _alias = _interopRequireDefault(require("./alias"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var getTheme = function getTheme() {
  var theme = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _default.default;
  return function () {
    return (0, _alias.default)(theme);
  };
};

var getDefaultTheme = getTheme(_default.default);
exports.getDefaultTheme = getDefaultTheme;