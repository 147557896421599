import { get } from 'lodash'
import GoogleAnalyticsPlugin from './plugins/GoogleAnalyticsPlugin'
import ConsolePlugin from './plugins/ConsolePlugin'

const googleAnalytics = get(window, 'emAppConfig.logger.plugins.googleAnalytics', false)
const consoleLogger = get(window, 'emAppConfig.logger.plugins.console', false)

const plugins = [
  {
    instance: GoogleAnalyticsPlugin,
    config: googleAnalytics,
  },
  {
    instance: ConsolePlugin,
    config: consoleLogger,
  },
].filter(p => p.config && p.config.enabled)


class LoggerService {
  constructor() {
    plugins.forEach((p) => {
      p.instance.init(p.config)
    })
  }
  dispatch(payload) {
    plugins.forEach((p) => {
      if (payload.logType && p.instance[payload.logType]) {
        p.instance[payload.logType](payload, p.config)
      } else {
        p.instance.log(payload, p.config)
      }
    })
  }
}

export default new LoggerService()
