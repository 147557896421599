import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ActionsService from 'actionsService/ActionsService'
import LoggerService from 'loggerService/LoggerService'
import NavigationPrompt from 'aspectUI/navigationPrompt/NavigationPrompt'

const { pages, appInitActionGroup } = window.emAppConfig

// Here we do the following:
// - Load the theme
// - Connect redux
// - Run initial action group (if any)
// - Set the page state (slice)
// ... Saddle the page with some goodies ...

export default function saddleThePage(pageName, pageComponent) {
  const mapStateToProps = ({
    page, data,
  }) => {
    return {
      pageData: page[pageName],
      appData: data,
    }
  }

  const PageComponent = pageComponent
  const pageConfig = pages[pageName]

  const loadPageDataHelper = function () {
    const opts = {
      name: pageName,
      config: pageConfig || {},
    }
    return ActionsService.fetchAndRunGroup('loadPage', opts)
  }

  const loadAppInitHelper = async function (router) {
    if (!appInitActionGroup || /login|logout/.test(pageName)) {
      return undefined
    }
    const { name, opts } = appInitActionGroup
    opts.router = router
    return ActionsService.fetchAndRunGroup(name, opts)
  }

  const loadPageInitHelper = async function (router) {
    if (!pageConfig || !pageConfig.initActionGroup) {
      return ActionsService.fetchAndRunGroup('pageSet', { pageName, name: 'initActionDone', data: true })
    }
    const { initActionGroup } = pageConfig
    const { name, opts } = initActionGroup
    opts.router = router
    return ActionsService.fetchAndRunGroup(name, opts).then(() => {
      ActionsService.fetchAndRunGroup('pageSet', { pageName, name: 'initActionDone', data: true })
    })
  }

  const loadAllPageData = async function (router) {
    await loadPageDataHelper()
    await loadAppInitHelper(router)
    await loadPageInitHelper(router)
  }

  class Connector extends React.Component {
    constructor(props) {
      super(props)

      loadAllPageData(props.router).then()

      this.state = {}

      this.execActionGroup = this.execActionGroup.bind(this)
    }
    static getDerivedStateFromProps(nextProps, prevState) {
      if (!prevState.hasResetData !== undefined && !(nextProps.pageData && nextProps.pageData.initActionDone)) {
        return { hasResetData: true }
      }
      return null
    }
    componentDidMount() {
      LoggerService.dispatch({
        logType: 'pageLoad',
        pageName,
      })
    }
    execActionGroup(name, opts) {
      const { router } = this.props
      opts.router = router
      opts.pageName = pageName
      return ActionsService.fetchAndRunGroup(name, opts)
    }
    render() {
      const { hasResetData } = this.state
      const { pageData, Theme, route } = this.props
      if (!hasResetData) return null
      if (!pageData || !pageData.initActionDone) {
        return null
      }

      if (route.hideTheme === true) {
        return <PageComponent {...this.props} execActionGroup={this.execActionGroup} />
      }

      return (
        <React.Fragment>
          <NavigationPrompt {...this.props} execActionGroup={this.execActionGroup} />
          <Theme {...this.props} execActionGroup={this.execActionGroup} Page={PageComponent} />
        </React.Fragment>
      )
    }
  }

  return withRouter(connect(
    mapStateToProps,
    {}, // Actions (we do not have any as of now)
  )(Connector))
}
