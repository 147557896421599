import React from 'react'
import DSLoadingIndicator from 'dsBasic/dsloadingindicator'

const LoadingIndicator = ({
  isOpen = false,
}) => {
  return (
    <DSLoadingIndicator
      id='em-loading'
      loading={isOpen}
      size='m'
    />
  )
}

export default LoadingIndicator
