/* eslint-disable max-lines */
import localEnvConfig from './envs/local'

(() => {
  const NOCONFIG = true
  window.emEnvConfig = NOCONFIG
  if (window.emEnvConfig === NOCONFIG) {
    console.info('<<< local config') // eslint-disable-line no-console
    window.emEnvConfig = localEnvConfig
  }

  window.emCustomAppConfig = {
    appName: 'Ellie Mae - Data Connect',
    theme: {
      name: 'corp-cool',
      config: {
        navigation: false,
        header: {
          tabs: [
            {
              label: 'Audit Reports',
              path: '/reports',
            },
            {
              label: 'Administration Settings',
              path: '/home',
            },
          ],
        },
      },
    },
    authentication: {
      name: 'ellieMae',
      requiresAuthDefault: true,
      basicToken: 'Basic OHNyeXFkc3k6Rm1iVjh0YnNUVlZDZVlVVkdPa0g3empSQW9HRjM1Vnp1TFhDb2pvZlcySHg4RmJKempreUJWZDk2U3h1dzBoUA==', // eslint-disable-line max-len
      authToken: 'access_token',
      tokenKey: 'token',
      grantAndScope: `grant_type=authorization_code&scope=${encodeURIComponent('dc2')}`,
      clientId: 'w0u7ddzo',
      scope: 'dc2',
      loginUrl: window.emEnvConfig.loginUrl,
      responseType: 'code',
      defaultLoginDest: '/',
      authOrigin: window.emEnvConfig.apiOrigin,
    },
    routes: [
      {
        path: '/home',
        page: 'AdminSettings',
        label: 'AdminSettings',
      },
      {
        path: '/register',
        page: 'Register',
        label: 'Register',
      },
      {
        path: '/',
        page: 'Reports',
        label: 'Reports',
      },
      {
        path: '/reports',
        page: 'Reports',
        label: 'Reports',
      },
      {
        path: '/summary/:reportId',
        page: 'Summary',
        label: 'Summary',
      },
      {
        path: '/createreport',
        page: 'CreateReport',
        label: 'CreateReport',
      },
      {
        path: '/edit_report/:reportId',
        page: 'EditReport',
        label: 'EditReport',
      },
    ],
    api: {
      defaultOpts: {
        useAuthToken: true,
        origin: window.emEnvConfig.dcOrigin,
        tokenType: '',
        fetchErrorActionGroup: {
          name: 'showModal',
          opts: {
            name: 'engineErrorModal',
            message: 'A network error has occurred.',
            canClose: true,
          },
        },
        codeErrorActionGroup: {
          name: 'showModal',
          opts: {
            name: 'engineErrorModal',
            message: 'An unexpected server error has occurred.',
            canClose: true,
          },
        },
      },
    },
    apis: {
      getCustomer: {
        url: '/api/secure/customer',
        opts: {
          codesAllowed: [401], // in addition to 200-299
        },
      },
      getReports: {
        url: '/api/secure/reports',
      },
      getReportSummary: {
        url: '/api/secure/summary/:reportId',
      },
      getCreateReport: {
        url: '/api/secure/createReport',
      },
      getEditReport: {
        url: '/api/secure/template/:reportId',
      },
      getMoreColumns: {
        url: '/api/secure/:type?fieldId=:query',
      },
      getMoreColumnsB: {
        url: '/api/secure/fieldsearchpage/:type?fieldId=:fieldPrefix&page=:page&pageSize=:pageSize',
      },
      getColumnDescription: {
        url: '/api/secure/fielddescriptors?[fieldId=:fieldIds]',
      },
      addReport: {
        url: '/api/secure/newaudit',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      updateReport: {
        url: '/api/secure/editaudit',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      deleteReport: {
        url: '/api/secure/deletereport',
      },
      runReport: {
        url: '/api/secure/runaudit/:reportId',
      },
      exportCSV: {
        url: '/api/secure/csv/:reportId',
      },
      registerInstance: {
        url: '/api/secure/customer',
        opts: {
          codesAllowed: [400], // in addition to 200-299
        },
      },
      enablePostDataSharing: {
        url: '/api/secure/customer/sharesfdata',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      disablePostDataSharing: {
        url: '/api/secure/customer/sharesfdata',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      getFieldsType: {
        url: '/api/secure/customer/fieldtype',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      addIP: {
        url: '/api/secure/customer/addIP',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      deleteIP: {
        url: '/api/secure/customer/deleteIP',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      UpdateStreamingEmail: {
        url: '/api/secure/customer/updateStreamingEmail',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      UpdateStreamingManifest: {
        url: '/api/secure/customer/updateStreamingManifest',
        opts: {
          codesAllowed: [500], // in addition to 200-299
        },
      },
      DeleteUser: {
        url: '/api/secure/customer/deleteUser',
        opts: {
            codesAllowed: [500], // in addition to 200-299
          },
      },
      AddUser: {
        url: '/api/secure/customer/addUser',
        opts: {
            codesAllowed: [500], // in addition to 200-299
          },
      },
      UpdateUser: {
        url: '/api/secure/customer/updateUser',
        opts: {
            codesAllowed: [500], // in addition to 200-299
          },
      },
    },
    pages: {
      AdminSettings: {
        initActionGroup: {
          name: 'genericOrchestration',
          opts: {
            name: 'ProvisionCheck',
            hash: 'customer',
          },
        },
      },
      Home: {
        fieldLabels: {
          title: 'GENERAL INFORMATION',
        },
      },
      Register: {
        initActionGroup: {
          name: 'genericOrchestration',
          opts: {
            name: 'ProvisionCheck',
            hash: 'customer',
          },
        },
      },
      Reports: {
        fieldLabels: {
          title: 'Reports',
        },
        initActionGroup: {
          name: 'genericOrchestration',
          opts: {
            name: 'GetReports',
            hash: 'reports',
          },
        },
      },
      Summary: {
        fieldLabels: {
          title: 'Summary Report',
        },
        initActionGroup: {
          name: 'genericOrchestration',
          opts: {
            name: 'ReportSummary',
            hash: 'reportSummary',
          },
        },
      },
      CreateReport: {
        fieldLabels: {
          title: 'Report Details',
        },
        initActionGroup: {
          name: 'genericApi',
          opts: {
            name: 'getCreateReport',
            hash: 'createReportData',
          },
        },
      },
      EditReport: {
        fieldLabels: {
          title: 'Report Details',
        },
        initActionGroup: {
          name: 'genericOrchestration',
          opts: {
            name: 'EditReport',
            hash: 'editReport',
          },
        },
      },
    },
    logger: {
      plugins: {
        googleAnalytics: {
          enabled: true,
          id: window.emEnvConfig.googleAnalyticsId,
        },
        console: {
          enabled: false,
        },
      },
    },
  }
})()
