"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getDefaultTheme", {
  enumerable: true,
  get: function get() {
    return _theme.getDefaultTheme;
  }
});

var _theme = require("./theme");