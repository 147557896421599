const getSiteId = (params) => {
  params.site_id = params.site_id || params.siteid || params.siteId || ''
  params.siteid = params.site_id
  return params
}

const getQueryParamObj = (search) => {
  return search
    .replace(/^\?/, '')
    .split('&')
    .map(v => ({
      [v.split('=')[0]]: v.split('=')[1],
    }))
    .reduce((a, b) => Object.assign(a, b), {})
}

export default (search) => {
  const queryParams = getQueryParamObj(search)
  const normalizedQueryParams = getSiteId(queryParams)

  /**
   * TEMP FIX: Fallback to parent window siteId if iframe siteid is not available
   */
  if ((!normalizedQueryParams.siteid || normalizedQueryParams.siteid === 'undefined') && window.parent) {
    const parentQueryParams = getQueryParamObj(window.location.search)
    const normalizedParentQueryParams = getSiteId(parentQueryParams)
    if (normalizedParentQueryParams.siteid) {
      normalizedQueryParams.siteid = normalizedParentQueryParams.siteid
      normalizedQueryParams.site_id = normalizedParentQueryParams.siteid
    }
  }
  return normalizedQueryParams
}

export const constructQueryString = (queryParams) => {
  const queryString = []
  Object.keys(queryParams).forEach((key) => {
    const param = `${key}=${queryParams[key]}`
    queryString.push(param)
  })
  return queryString.join('&')
}
