require("core-js/modules/es.array.reduce");

require("core-js/modules/es.object.keys");

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n      @media (max-width: ", ") {\n        ", ";\n      }\n    "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var _require = require('styled-components'),
    css = _require.css;

module.exports = function (breakpoints) {
  return Object.keys(breakpoints).reduce(function (acc, label) {
    acc[label] = function () {
      return css(_templateObject(), breakpoints[label], css.apply(void 0, arguments));
    };

    return acc;
  }, {});
};