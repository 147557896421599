import React from 'react'
import {
  Route,
  Redirect,
  Switch,
} from 'react-router-dom'
import RuntimeImportService from 'runtimeImportService/RuntimeImportService'
import saddleThePage from 'app/saddleThePage'
import { isActiveSession } from 'utilities/auth'
import buildRoutesFromMenu from 'utilities/buildRoutesFromMenu'

const Authentication = ({ ...props }) => {
  const { authentication } = window.emAppConfig
  const { route } = props
  const { requiresAuth } = route
  const isAuthEnabled = (requiresAuth === true) || (authentication.requiresAuthDefault === true && requiresAuth !== false)
  if (isAuthEnabled && !isActiveSession()) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { },
        }}
      />
    )
  }

  return <Loader {...props} />
}

class Loader extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadedPage: false,
      loadedTheme: false,
    }
    this.setPageResult = this.setPageResult.bind(this)
  }
  setPageResult(result) {
    const { route } = this.props
    const pageName = route.page

    let Page = result[pageName]
    Page = saddleThePage(pageName, Page)
    this.setState({ loadedPage: true, Page, pageExtra: result })
  }
  render() { // eslint-disable-line max-statements
    const { loadedPage, loadedTheme } = this.state
    if (!loadedPage) {
      const { route } = this.props
      const pageName = route.page

      if (route.runtimeDynamicConfig) {
        const config = { name: route.page, ...route.runtimeDynamicConfig }
        RuntimeImportService.performMysticalOperation(config)
          .then(this.setPageResult)
      } else if (route.internal) {
        import(`internalPages/${pageName}/index`)
          .then(this.setPageResult)
      } else {
        import(/* webpackChunkName: "[request]" */ `customUI/pages/${pageName}/index`)
          .then(this.setPageResult)
      }

      return null
    }
    if (!loadedTheme) {
      const { theme } = window.emAppConfig
      import(
        /* webpackExclude: /DISABLED/ */
        `themes/${theme.name}/index`).then((result) => {
        const Theme = result.default
        this.setState({ loadedTheme: true, Theme })
      })

      return null
    }

    const { Page, pageExtra, Theme } = this.state
    return <Page {...this.props} pageExtra={pageExtra} Theme={Theme} />
  }
}

export default function buildRoutes() {
  const { menu } = window.emAppConfig
  let { routes } = window.emAppConfig
  if (menu) {
    routes = buildRoutesFromMenu(menu)
  }

  const routesWithAuth = [
    ...routes,
    {
      path: '/login',
      page: 'Login',
      internal: true,
      requiresAuth: false,
      hideTheme: true,
    },
    {
      path: '/logout',
      page: 'Logout',
      internal: true,
      requiresAuth: false,
      hideTheme: true,
    },
  ]
  const noRoute = {
    path: '*',
    page: 'NoRoute',
    internal: true,
    requiresAuth: false,
    hideTheme: false,
  }
  let hasNoRoute = false
  return (
    <Switch>
      {routesWithAuth.map((route, index) => {
        hasNoRoute = hasNoRoute || route.path === '*'
        return (
          <Route
            key={index}
            exact={route.path === '/'}
            path={route.path}
            render={(router) => {
              return React.createElement(
                Authentication,
                {
                  ...{},
                  ...{ router },
                  route,
                },
              )
            }}
          />
        )
      })}
      {
        !hasNoRoute
        && (
          <Route
            key='NoRoute'
            exact={noRoute.path === '/'}
            path={noRoute.path}
            render={(router) => {
              return React.createElement(
                Authentication,
                {
                  ...{},
                  ...{ router },
                  route: noRoute,
                },
              )
            }}
          />
        )
      }
    </Switch>
  )
}
