/* eslint-disable no-console */
import { createLogger } from 'redux-logger'

const actionTransformer = (action) => {
  if (action.type === 'BATCHING_REDUCER.BATCH') {
    action.payload.type = action.payload.map(next => next.type).join(' => ')
    return action.payload
  }

  return action
}

const level = 'info'
const logger = {}

// eslint-disable-next-line no-restricted-syntax,no-loops/no-loops
for (const method in console) {
  if (typeof console[method] === 'function') {
    logger[method] = console[method].bind(console)
  }
}

// eslint-disable-next-line consistent-return
logger[level] = (...args) => {
  const lastArg = args.pop()

  if (Array.isArray(lastArg)) {
    return lastArg.forEach((item) => {
      console[level](...args, item)
    })
  }

  console[level](...args)
  console.log('==========>  ', lastArg)
}

export default createLogger({
  level,
  actionTransformer,
  logger,
  collapsed: true,
})
