require("core-js/modules/es.string.bold");

require("core-js/modules/es.string.small");

var media = require('./media');
/* eslint-disable no-param-reassign, max-statements, prefer-destructuring */


module.exports = function (theme) {
  // space alias
  theme.space.xxxs = theme.space[1];
  theme.space.xxs = theme.space[2];
  theme.space.xxs2 = theme.space[3];
  theme.space.xs = theme.space[4];
  theme.space.s = theme.space[5];
  theme.space.m = theme.space[6];
  theme.space.l = theme.space[7];
  theme.space.xl = theme.space[8];
  theme.space.xxl = theme.space[9]; // font wieght alias

  theme.fontWeights.thin = theme.fontWeights[0];
  theme.fontWeights.light = theme.fontWeights[1];
  theme.fontWeights.regular = theme.fontWeights[3];
  theme.fontWeights.semibold = theme.fontWeights[5];
  theme.fontWeights.bold = theme.fontWeights[8]; // color alias

  theme.colors.neutral['000'] = theme.colors.neutral[0];
  theme.colors.neutral['050'] = theme.colors.neutral[1];
  theme.colors.neutral['080'] = theme.colors.neutral[2];
  theme.colors.neutral['100'] = theme.colors.neutral[3];
  theme.colors.neutral['200'] = theme.colors.neutral[4];
  theme.colors.neutral['300'] = theme.colors.neutral[5];
  theme.colors.neutral['400'] = theme.colors.neutral[6];
  theme.colors.neutral['500'] = theme.colors.neutral[7];
  theme.colors.neutral['600'] = theme.colors.neutral[8];
  theme.colors.neutral['700'] = theme.colors.neutral[9];
  theme.colors.neutral['800'] = theme.colors.neutral[10];
  theme.colors.brand['100'] = theme.colors.brand[0];
  theme.colors.brand['200'] = theme.colors.brand[1];
  theme.colors.brand['300'] = theme.colors.brand[2];
  theme.colors.brand['400'] = theme.colors.brand[3];
  theme.colors.brand['500'] = theme.colors.brand[4];
  theme.colors.brand['600'] = theme.colors.brand[5];
  theme.colors.brand['700'] = theme.colors.brand[6];
  theme.colors.brand['800'] = theme.colors.brand[7];
  theme.colors.success['300'] = theme.colors.success[0];
  theme.colors.success['900'] = theme.colors.success[1];
  theme.colors.warning['400'] = theme.colors.warning[0];
  theme.colors.warning['500'] = theme.colors.warning[1];
  theme.colors.warning['600'] = theme.colors.warning[2];
  theme.colors.danger['200'] = theme.colors.danger[0];
  theme.colors.danger['900'] = theme.colors.danger[1]; // breakpoints

  theme.breakpoints.small = theme.breakpoints[0];
  theme.breakpoints.medium = theme.breakpoints[1];
  theme.breakpoints.large = theme.breakpoints[2]; // media queries alias

  theme.media = media({
    small: theme.breakpoints.small,
    medium: theme.breakpoints.medium,
    large: theme.breakpoints.large
  });
  return theme;
};